<template>
   <b-row
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <!-- breadcrumb items -->
              <b-breadcrumb-item
                v-for="item in breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="{ name: item.to, params: 
                item.params }"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <template>
    <b-col
      class="content-header-right text-md-right d-block mb-1"
      md="3"
      cols="12"
    >
     <!--  <b-dropdown
        variant="link"
        no-caret
        toggle-class="p-0"
        right
      >

        <template #button-content> -->
          <b-button :to="manageLink"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="btn-icon"
            style="padding:0.6rem;"
            v-b-tooltip.hover.bottom="`${$t('dash.cards.go_to')} ${$t('Help')}`"
          >
            <feather-icon icon="HelpCircleIcon" style="width: 20px; height: 20px;" />
          </b-button>
<!--         </template>
 -->        
         <!--  <b-dropdown-item v-for="option, index in options" 
            :key="index" 
            @click="$emit(option.action)"
          >
            <feather-icon
              :icon="option.icon"
              size="16"
              :style="{color: option.color}"
            />
            <span :style="{color: option.color}" class="align-middle ml-50">{{ option.name }}</span>
          </b-dropdown-item> -->

<!-- borrar 
        <b-dropdown-item :to="{ name: 'apps-todo' }">
          <feather-icon
            icon="CheckSquareIcon"
            size="16"
          />
          <span class="align-middle ml-50">Todo</span>
        </b-dropdown-item>
-->
      
      <!-- </b-dropdown> -->
    </b-col>
        </template>

  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem, BButton,
  VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  props:{
    pageTitle: {
      type: String,
      required: true
    },
    breadcrumb: {
      type: Array,
      required: true
    },
    helpLink:{
      type: Object, // { name: 'faq', params: ... }
      required:false
    }
  },
  computed:{
    manageLink(){
      if(this.helpLink) return this.helpLink
      return { name: 'faq' }
    }
  }
}
</script>
